import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/expertise.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const ReactJs = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              Expert ReactJS Development
            </h1>
            <p className="text-base md:text-lg font-light">
              Elevate your online presence with StarOps - your trusted partner
              for expert ReactJS development. Our skilled team crafts tailored
              web applications, ensuring innovation and seamless alignment with
              your business goals. Unleash the power of ReactJS for a superior
              user experience. Elevate with StarOps.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Innovating with ReactJS"
          description="StarOps transforms digital experiences with cutting-edge ReactJS solutions, delivering innovation, excellence, and impact for your brand."
        />
        <div className="mt-10 flex  flex-col md:flex-row gap-4">
          <p className="w-full md:w-2/3 font-light leading-6 items-center justify-center">
            Welcome to StarOps, your gateway to transformative ReactJS
            development services. As a dynamic and client-centric organization,
            we specialize in crafting innovative web applications that redefine
            user experiences through the power of ReactJS.
            <br />
            Our seasoned experts, recognized for their award-winning prowess,
            passionately bring your ideas to life, ensuring each ReactJS-powered
            website is a unique reflection of your brand's identity.
            <br />
            At StarOps, we understand that the digital landscape craves more
            than just functional websites; it demands innovative solutions that
            captivate and resonate with users. Leveraging our expertise in
            ReactJS, we embark on a collaborative journey with our clients,
            transforming concepts into intuitive, visually stunning, and
            feature-rich web applications.
            <br />
            Our commitment to excellence is reflected in our agile ReactJS
            development methodology, ensuring we adapt efficiently to the
            evolving needs of your project. Security and confidentiality remain
            at the forefront of our priorities, assuring you of a trustworthy
            partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where every
            line of ReactJS code is meticulously crafted, and every website we
            deliver is an embodiment of your aspirations. Join us in shaping the
            future of web experiences, one innovative ReactJS project at a time.
          </p>
          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-3/4"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/ban_reactjs.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Explore StarOps Offerings"
          description="Discover our diverse solutions, where innovation meets expertise, ensuring tailored excellence for your unique business needs."
        />
        <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.reactjs.whatweoffer.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
          <div className="row-start-1 row-end-3">
            <img
              className="h-full"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/reactjs.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 py-4 bg-gradient-to-r from-slate-100 to-slate-100">
        <div className=" p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Why Choose Us: Unlocking Exceptional Benefits"
            description="Experience unparalleled advantages as we bring expertise, innovation, and reliability to elevate your project to new heights."
          />

          <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.reactjs.whychoose.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.reactjs.FAQ} />
      </div>
    </>
  );
};

export default ReactJs;
