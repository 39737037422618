import React from "react";
import blogData from "../../data/blog/blogs.json";
import RenderProfile from "../../Components/Common/RenderProfile";
import { Helmet } from "react-helmet-async";

const TheBlogCard = ({ data }) => {
  return (
    <div className="border shadow-sm p-5 rounded-lg">
      <div className="flex  justify-between items-start">
        <RenderProfile name={data.author} />
        <p className="text-sm font-semibold text-gray-700">{data.date}</p>
      </div>

      <a
        href={`/blog/${data.title}`}
        className="mt-3 flex flex-col sm:flex-row items-center gap-3"
      >
        <p className="font-light order-2 sm:order-1">
          <span className="font-semibold pb-4">
            {data.heading} <br />
          </span>
          {data.description}
          <span className=" text-gray-600 hover:text-logo"> read more...</span>
        </p>
        <img
          className="h-28 aspect-video order-1  sm:order-2 rounded-lg"
          src={data.posterUrl}
          alt="blog poster StarOps"
        />
      </a>
      <div className="mt-2 flex items-center gap-3">
        <img
          className="h-6"
          src="https://cdn.staropstech.com/starops/assets/blogs/like.png"
          alt=""
        />
        <img
          className="h-6"
          src="https://cdn.staropstech.com/starops/assets/blogs/save.png"
          alt=""
        />
      </div>
    </div>
  );
};

const BlogMainPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Blogs | StarOps Technologies Pvt Ltd | IT, Web, App, and DevOps{" "}
        </title>
        <meta
          name="description"
          content="StarOps delivers cutting-edge solutions, transforming visions into reality. Elevate your business with our innovative services and expert guidance."
        />
      </Helmet>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              Empowering Insights: Explore Our Team's Knowledge Through Blogs
            </h1>
            <p className="text-base md:text-lg font-light">
              Explore StarOps' expertise in IT services and consulting through
              insightful blogs. Stay updated on the latest trends in IT,
              business services, and software development. Your trusted partner
              in digital transformation, headquartered in Greater Noida with
              offices in Lucknow and Rajasthan.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <div className="flex w-full gap-5 px-6">
          <div className="flex flex-row items-center justify-center gap-1">
            <img
              className="h-6 aspect-square"
              src="https://cdn.staropstech.com/starops/assets/blogs/hot-topic.png"
              alt=""
            />
            <p className="text-lg hover:text-logo">Hot</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-1">
            <img
              className="h-6 aspect-square"
              src="https://cdn.staropstech.com/starops/assets/blogs/latest.png"
              alt=""
            />
            <p className="text-lg hover:text-logo">Latest</p>
          </div>
        </div>
      </div>
      <div className="mt-5 p-2 md:p-0 container mx-auto  flex flex-row items-start justify-center ">
        <div className="w-full md:w-2/3 p-6 rounded-lg flex flex-col gap-2">
          {blogData.blogs.map((value, key) => (
            <TheBlogCard data={value} key={key} />
          ))}
        </div>
        <div className="hidden md:flex md:w-1/3   m-6 border p-4  flex-col gap-4  rounded-lg">
          <h1 className="text-gray-700 font-semibold">
            Top Writer of This Week
          </h1>
          <RenderProfile name={blogData.topWriter} />
        </div>
      </div>
    </>
  );
};

export default BlogMainPage;
