import React, { useState } from "react";
import { filterJobs } from "../utils/Healpers";
import data from "../data/careers/careers.json";
import { Helmet } from "react-helmet-async";

const CareersCard = ({ data }) => {
  return (
    <>
      <div className="border w-full rounded-lg p-4">
        <div className="flex sm:flex-row flex-col items-start sm:items-center justify-between gap-2 sm:gap-4 w-full min-w-full">
          <div className="flex flex-row  items-center gap-2">
            <img
              className="h-4 sm:h-6"
              src="https://cdn-icons-png.flaticon.com/128/6964/6964169.png"
              alt="job"
            />
            <h1 className="text-base font-semibold sm:text-xl sm:font-semibold text-gray-700 ">
              {data.job_title}
            </h1>
          </div>
          <div className="flex flex-row items-center">
            <img
              className="h-2 sm:h-3"
              src="https://cdn-icons-png.flaticon.com/128/25/25473.png"
              alt="job"
            />
            <h1 className="text-sm font-medium sm:font-semibold">
              {data.stipend}K
              <span className="font-medium text-gray-500">
                / {data.stipend_type}
              </span>
            </h1>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row items-start gap-2 md:items-center justify-between mt-10">
          <div className="flex flex-row gap-3 w-full md:w-auto flex-wrap">
            {data.tags.map((value, key) => (
              <p
                key={key}
                className="p-2 w-max text-sm sm:text-base text-nowrap  bg-gray-300 rounded-lg "
              >
                {value}
              </p>
            ))}
          </div>
          <button
            disabled
            className="hover:cursor-not-allowed p-2 text-sm sm:text-base  rounded-lg bg-logo text-white font-semibold"
          >
            Apply Now
          </button>
        </div>
      </div>
    </>
  );
};

const CareerPage = () => {
  const [jobdata, setJobData] = useState(data.jobs);
  const [isDropDownOpen, setIsDeopDownOpen] = useState(false);
  const [jobLocation, setJobLocation] = useState("Job Location");
  const [searChar, setSearChar] = useState("");
  const HanleSearch = (e) => {
    e.preventDefault();
    const filterdata = filterJobs(data.jobs, searChar, jobLocation);
    setJobData(filterdata);
  };
  return (
    <>
      <Helmet>
        <title>
          Career | StarOps Technologies | Join Our IT, Web, App, & DevOps Team
        </title>
        <meta
          name="description"
          content="Explore career opportunities at StarOps Technologies. Join our team of innovators in IT, web, app development, and DevOps. Start your journey towards excellence and grow with us."
        />
      </Helmet>
      <div
        className="relative flex justify-center items-center bg-cover bg-center min-h-[45vh]"
        style={{
          backgroundImage: `url(https://storage.googleapis.com/gdmnd7sle/sv1s10qqat/join-the-team.jpg)`,
        }}
      >
        <div className="absolute z-[1] inset-0 bg-black opacity-40"></div>
        <div className="z-[2] w-full flex items-center flex-col text-white ">
          <h1 className="text-4xl md:text-7xl font-semibold">Join the Team</h1>
          <p className="text-base md:text-xl text-center">
            Relaxed Atmosphere, Best Working Environment, Top Notch Projects...
          </p>
        </div>
      </div>
      <div className="mt-10 p-4 sm:px-20 md:px-28 container mx-auto flex flex-col items-center justify-center border bg-gray-50 bg-opacity-40 rounded-lg shadow-md">
        <form className="w-full grid grid-cols-2 md:grid-cols-6">
          <div className="relative col-span-3 flex items-center">
            <img
              className="h-6 left-2 absolute top-1/2 transform -translate-y-1/2 aspect-square"
              src="https://cdn-icons-png.flaticon.com/128/16/16492.png"
              alt=""
            />
            <input
              onChange={(e) => setSearChar(e.target.value)}
              value={searChar}
              className="border w-full h-full px-4 py-2 rounded-lg md:rounded-l-md outline-none text-base pl-10"
              placeholder="job title, job code..."
            />
          </div>
          <div className="relative col-span-2 flex items-center">
            <img
              className="h-6 left-2 absolute top-1/2 transform -translate-y-1/2 aspect-square"
              src="https://cdn-icons-png.flaticon.com/128/446/446075.png"
              alt=""
            />
            <div
              onClick={() => setIsDeopDownOpen((prev) => !prev)}
              className="border rounded-l-md md:border w-full h-full  px-4 py-2 outline-none text-base pl-10"
            >
              <div className="flex w-full items-center justify-between">
                <p>{jobLocation}</p>
                <img
                  className="h-6"
                  src="https://cdn-icons-png.flaticon.com/128/2722/2722987.png"
                  alt="down"
                />
              </div>
            </div>
            {isDropDownOpen && (
              <div className="absolute w-full bottom-0 translate-y-full border shadow-md bg-white rounded-b-lg ">
                <ul>
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => {
                      setJobLocation("Job Location");
                      setIsDeopDownOpen((prev) => !prev);
                    }}
                  >
                    All Locations
                  </li>
                  <hr />
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => {
                      setJobLocation("Greater Noida");
                      setIsDeopDownOpen((prev) => !prev);
                    }}
                  >
                    Greater Noida
                  </li>
                  <hr />
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => {
                      setJobLocation("Remote");
                      setIsDeopDownOpen((prev) => !prev);
                    }}
                  >
                    Remote
                  </li>
                </ul>
              </div>
            )}
          </div>
          <button
            onClick={HanleSearch}
            className="border col-span-1  px-4 py-2 flex rounded-r-md items-center justify-center bg-logo text-white font-semibold"
          >
            Search
          </button>
        </form>
        <div className="my-5 sm:my-10 w-full gap-4 flex flex-col items-center justify-center">
          <p className="w-full sm:w-2/3 text-center text-2xl text-slate-700 font-medium py-5">
            Unlock exciting opportunities! Elevate your career journey with us.
            <br />
            Drop your resume at{" "}
            <a href={"mailto:contact@staropstech.com"} className="text-logo/60">
              contact@staropstech.com
            </a>{" "}
            and let's shape success together!
          </p>
          {/* {jobdata.length === 0 ? (
            <p className="text-2xl text-slate-700 font-medium">
              Currently No jobs Avilable
            </p>
          ) : (
            <>
              {jobdata.map((value, key) => (
                <CareersCard data={value} key={key} />
              ))}
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default CareerPage;
