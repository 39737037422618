import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/expertise.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const Python = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              Expert Python Development
            </h1>
            <p className="text-base md:text-lg font-light">
              Boost your digital capabilities with StarOps - your reliable
              partner for expert Python development. Our proficient team designs
              customized solutions, ensuring innovation and seamless alignment
              with your business objectives. Harness the potential of Python for
              robust applications and elevate your online presence. Advance with
              StarOps.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Pioneering Python Development"
          description="StarOps revolutionizes digital experiences with state-of-the-art Python solutions, providing innovation, excellence, and impact for your brand."
        />

        <div className="mt-10 flex  flex-col md:flex-row gap-4 items-center justify-center">
          <p className="w-full md:w-2/3 font-light leading-6 ">
            Welcome to StarOps, your doorway to revolutionary Python development
            services. As a dynamic and client-centric organization, we
            specialize in creating cutting-edge applications that redefine user
            experiences through the power of Python.
            <br />
            Our seasoned experts, acknowledged for their award-winning
            capabilities, passionately bring your ideas to life, ensuring each
            Python-powered solution is a distinctive representation of your
            brand's essence.
            <br />
            At StarOps, we recognize that the digital landscape demands more
            than just functional applications; it requires innovative solutions
            that captivate and resonate with users. Leveraging our expertise in
            Python, we embark on a collaborative journey with our clients,
            transforming concepts into intuitive, visually appealing, and
            feature-rich applications.
            <br />
            Our commitment to excellence is mirrored in our agile Python
            development methodology, ensuring efficient adaptation to the
            evolving needs of your project. Security and confidentiality remain
            paramount in our priorities, providing you with a reliable
            partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where every
            line of Python code is meticulously crafted, and every application
            we deliver is an embodiment of your aspirations. Join us in shaping
            the future of digital experiences, one innovative Python project at
            a time.
          </p>
          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-3/4"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/ban_python.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Explore StarOps Offerings"
          description="Discover our diverse solutions, where innovation meets expertise, ensuring tailored excellence for your unique business needs."
        />
        <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.python.whatweoffer.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
          <div className="row-start-1 row-end-3">
            <img
              className="h-full"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/python.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 py-4 bg-gradient-to-r from-slate-100 to-slate-100">
        <div className=" p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Why Choose Us: Unlocking Exceptional Benefits"
            description="Experience unparalleled advantages as we bring expertise, innovation, and reliability to elevate your project to new heights."
          />

          <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.python.whychoose.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.python.FAQ} />
      </div>
    </>
  );
};

export default Python;
