import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SectionHeading from "../Components/Common/SectionHeading";
import Review from "../Components/Common/Review";
import ServiceForm from "../Components/Common/ServiceForm";
import SimpleCard from "../Components/Common/SimpleCard";
import RecentsBlogs from "../Components/Common/RecentsBlogs";
import data from "../data/homepage.json";
import recentblogsdata from "../data/blog/blogs.json";
import "../customstyles.css";

const SoftwareDevCard = ({ data }) => {
  return (
    <div className="flex flex-col items-center justify-between text-center gap-2 p-2 px-4 border border-logo hover:shadow-xl hover:scale-105 duration-500 ease-in-out  rounded-lg">
      <div className="flex items-center justify-start gap-3 w-full">
        <img className="h-8" src={data.iconUrl} alt={data.title} />
        <h1 className="text-lg">{data.title}</h1>
      </div>
      <p className="font-light text-justify ">{data.description}</p>
      <Link
        to={data.pathUrl}
        className="bg-logo rounded-lg  shadow-blue-300 shadow-sm px-3 py-1 text-white "
      >
        Read More
      </Link>
    </div>
  );
};

const IconCard = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="aspect-square hover:bg-slate-50 px-4 py-5 flex flex-col justify-center items-center gap-2 rounded-lg group icon_card"
    >
      <img
        className={`h-12 icon ${isHovered ? "hovered" : ""}`}
        src={isHovered ? data.colourIcon : data.iconUrl}
        alt={data.name}
      />
      <h1>{data.name}</h1>
    </div>
  );
};

const ExpertiseIconCard = ({ data }) => {
  return (
    <div className="h-28 hover:bg-slate-50 px-4 py-5 flex flex-col justify-center items-center gap-2 rounded-lg">
      <img className="h-12" src={data.iconUrl} alt={data.name} />
      <h1 className=" text-slate-800  font-thin">{data.name}</h1>
    </div>
  );
};

const Homepage = () => {
  const [iconsData, setIconsData] = useState(data.icons.devops);
  const techandExperties = [
    "Devops",
    "Backend",
    "Frontend",
    "Mobile",
    "Database",
    "CMS",
  ];
  const [selectedIcon, SetSelectedIcon] = useState("Devops");

  const SetIconsonHover = (techstack) => {
    SetSelectedIcon(techstack);
    switch (techstack) {
      case "Devops":
        setIconsData(data.icons.devops);
        break;
      case "Backend":
        setIconsData(data.icons.backend);

        break;
      case "Frontend":
        setIconsData(data.icons.frontend);

        break;
      case "Mobile":
        setIconsData(data.icons.mobile);

        break;
      case "Database":
        setIconsData(data.icons.database);
        break;
      case "CMS":
        setIconsData(data.icons.cms);
        break;
      default:
        setIconsData(data.icons.devops);
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);
    // Scroll to the element if there's a hash in the initial URL
    handleHashChange();
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
          StarOps Technologies Pvt Ltd | IT, Web, App, and DevOps Excellence
        </title>
        <meta
          name="description"
          content="Discover how StarOps drives business excellence through innovative IT solutions, web and app development, and top-tier DevOps services. Explore how our strategic approach ensures unmatched efficiency, propelling your business to success. Learn more about our cutting-edge offerings in this comprehensive article."
        />
      </Helmet>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center  text-white w-11/12 md:w-1/2   ">
            <h1 className="text-3xl mb-5   md:text-5xl   md:mb-10">
              Your Trusted Software Development Company
            </h1>
            <p className="text-base md:text-lg">
              StarOps is a leading offshore software development company based
              in India. With a strong reputation for delivering innovative and
              high-quality software solutions, StarOps is a trusted partner for
              businesses seeking to leverage the advantages of offshore
              development.
            </p>
            <Link
              to={"/contact"}
              className="py-4 px-10  border border-white hover:bg-white  hover:text-[#427ee6]  rounded-2xl my-5"
            >
              Schedule a Call
            </Link>
          </div>
          <div className="flex  justify-end  items-center p-2 md:p-0 w-11/12 md:w-1/2 ">
            <img
              className="w-2/3"
              src="https://cdn.staropstech.com/starops/assets/pages_banner/homepage.webp"
              alt="starops Intraction"
            />
          </div>
        </div>
      </div>
      {/*===================*/}
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Our Software Development Process"
          description="Experience excellence with our industry-best software development practices.
      Our dedicated team ensures optimal outcomes at every stage."
        />

        <div className="h-32 md:h-60 flex items-center w-full">
          <div className="relative border-t-2 border-black w-full">
            <div className="absolute top-0 left-0 border rounded-full transform translate-y-[-50%] h-4 w-4 bg-logo animate-moveThroughLine"></div>
            <div className="absolute   w-full top-0 left-0 transform translate-y-[-50%] flex flex-row items-center justify-between ">
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#FAB417] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/discover.png"
                  alt=""
                />
                <p className="absolute hidden md:block mt-5 w-32 text-center left-1/2 translate-x-[-50%]">
                  Discovery
                </p>
              </div>
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#473DFF] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/ux-design.png"
                  alt=""
                />
                <p className=" absolute hidden md:block  mt-5 w-32 text-center left-1/2 translate-x-[-50%]">
                  UI/UX Design
                </p>
              </div>
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#FA3B3D] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/development.png"
                  alt=""
                />
                <p className=" absolute hidden md:block  mt-5 w-32 text-center left-1/2 translate-x-[-50%]">
                  Development
                </p>
              </div>
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#005FE3] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/coding.png"
                  alt=""
                />
                <p className=" absolute hidden md:block  mt-5 w-32 text-center left-1/2 translate-x-[-50%]">
                  Coding
                </p>
              </div>
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#59B57B] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/testing.png"
                  alt=""
                />
                <p className="absolute hidden md:block  mt-5 w-32 text-center left-1/2 translate-x-[-50%] ">
                  Testing
                </p>
              </div>
              <div className="relative w-10 h-10 md:w-16 md:h-16 rounded-lg bg-[#CF88E9] p-1 shadow-lg">
                <img
                  src="https://cdn.staropstech.com/starops/assets/process-bar/delivery.png"
                  alt=""
                />
                <p className="absolute hidden md:block  mt-5 w-32 text-center left-1/2 translate-x-[-50%]  ">
                  Delivery
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===================*/}
      <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Our Software Development Services"
          description="Our elite team delivers optimal results, adhering to industry best practices with precision and expertise."
        />
        <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.developmentservices.map((value, key) => {
            return <SoftwareDevCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Tech and Expertise"
          description="Our team excels in industry best practices, delivering top-notch results with unparalleled tech expertise."
        />
        <div className="w-full mt-10">
          <div className="w-full grid grid-cols-3 border-b-2 sm:grid-cols-3 md:grid-cols-6 mx-auto gap-3">
            {techandExperties.map((value, key) => {
              return (
                <div
                  key={key}
                  onClick={() => SetIconsonHover(value)}
                  onMouseOver={() => SetIconsonHover(value)}
                  className={`${
                    selectedIcon === value
                      ? "text-logo border-b border-logo scale-105 transition-all duration-500 ease-in-out"
                      : ""
                  } text-2xl  hover:text-logo  hover:border-b hover:border-logo hover:scale-105 transition-all duration-500 ease-in-out`}
                >
                  <p className="text-center">{value}</p>
                </div>
              );
            })}
          </div>
          <div className="my-2 grid grid-cols-3 md:grid-cols-6   ">
            {iconsData &&
              iconsData.map((value, key) => {
                return <IconCard data={value} key={key} />;
              })}
          </div>
        </div>
      </div>
      <div className="mt-10 p-4 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Why to choose StarOps"
          description="Our team delivers optimal results, following industry best practices for unparalleled performance."
        />
        <div className="mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.whychooseus.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Expertise Across Diverse Industries "
          description="Our exceptional team ensures optimal results by adhering to industry best practices across diverse sectors."
        />
        <div className="mt-10 grid gap-3 grid-cols-3 sm:grid-cols-4 md:grid-cols-6 w-full">
          {data.diverseindustries.map((value, key) => {
            return <ExpertiseIconCard data={value} key={key} />;
          })}
        </div>
      </div>
      {/* ============== */}
      <div
        id="testimonials"
        className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center"
      >
        <SectionHeading
          heading="Hear from Our Clients"
          description="Discover excellence through the voices of satisfied clients. Our best practices ensure results that speak for themselves."
        />
      </div>
      <Review />
      {/* ============== */}
      <RecentsBlogs recents={recentblogsdata.recentblog} />
      <ServiceForm />
    </>
  );
};

export default Homepage;
