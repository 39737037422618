import React from "react";
import { Link } from "react-router-dom";

const NavbarImagecard = ({ data, setNavOpen }) => {
  return (
    <Link
      onClick={() => setNavOpen((prev) => !prev)}
      to={data?.url}
      className="relative flex justify-center items-center bg-cover bg-center h-32 rounded-lg hover:scale-105 transition-all duration-500 ease-in-out"
      style={{ backgroundImage: `url(${data.iconUrl})` }}
    >
      <div className="absolute inset-0 bg-black opacity-30 hover:opacity-10 transition-all duration-500 ease-in-out rounded-lg"></div>
      {/* <h1 className="text-2xl text-white  shadow-lg z-20">{data?.title}</h1> */}
    </Link>
  );
};

export default NavbarImagecard;
