import React from "react";
import logostaropswhite from "../../Assets/logowhite.png";

const Footer = () => {
  return (
    <div className="mt-20 bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
      <div className="container p-2 md:p-0  mx-auto text-white  font-thin grid grid-cols-2  md:grid-cols-4 gap-5 sm:gap-14">
        <div className="flex flex-col col-span-2 md:col-span-1">
          <img
            src={logostaropswhite}
            alt="starops logo"
            width="100"
            height="50"
          />
          <p className="text-justify text-sm ">
            StarOps: Premier IT services office in Greater Noida. Specializing
            in digital transformations, we excel in and Cloud Computing,
            delivering innovation and excellence.
          </p>
          <p className="leading-5 mt-1">
            Ph:{" "}
            <a rel="noreferrer" target="_blank" href={`tel:+917530882800`}>
              +91 7530882800
            </a>
          </p>
          <p className="leading-5">
            Email:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:contact@staropstech.com`}
            >
              contact@staropstech.com
            </a>
          </p>
          <div className="flex flex-row justify-start gap-4 items-center mt-4">
            <a
              href="https://www.linkedin.com/company/starops-technologies"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-10 white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/3536/3536505.png"
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/staropstech/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className="h-10 white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
                alt=""
              />
            </a>
            <a
              href="https://twitter.com/StarOpsTech"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-9  white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/5968/5968830.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <iframe
            title="staropstech address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14026.540398126008!2d77.49010353017052!3d28.490533920553517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cea5df6ca01fd%3A0x78a54533cd2ed38!2sGamma%20II%2C%20Greater%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1709106202029!5m2!1sen!2sin"
            className="rounded-lg w-full h-full "
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2 ">Company</h1>
          <ul>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/#testimonials">Testimionials</a>
            </li>
            <li>
              <a href="/careers">Careers</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="#">Terms & Conditions</a>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2 ">Our Services</h1>
          <ul>
            <li>
              <a href="/services/devops">DevOps</a>
            </li>
            <li>
              <a href="/services/webdevelopment">Website Development</a>
            </li>
            <li>
              <a href="/services/appdevelopment">Mobile App Development</a>
            </li>
            <li>
              <a href="/services/itservices">IT Services</a>
            </li>
            <li>
              <a href="#">SaaS Services</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
