import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/services.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const SAAS = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              DevOps Solutions
            </h1>
            <p className="text-base md:text-lg font-light">
              StarOps specializes in cutting-edge DevOps solutions, optimizing
              software development and IT operations. Renowned for innovation,
              we streamline pipelines, improve workflows, and deliver
              high-quality services. Our skilled professionals tailor solutions
              to automate tasks, enhance deployment processes, and seamlessly
              integrate DevOps practices, ensuring your business achieves
              heightened operational efficiency.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-10 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="DevOps Solutions for Seamless Operations"
          description="Empowering your business with efficient DevOps practices for streamlined software development and IT operations."
        />
        <div className="mt-5 flex  flex-col md:flex-row gap-4 justify-center items-center">
          <p className="w-full md:w-2/3 font-light leading-6">
            Welcome to StarOps, your strategic partner for transformative DevOps
            solutions. As a dynamic and client-centric organization, we
            specialize in implementing DevOps practices that enhance
            collaboration and efficiency in your software development and IT
            operations.
            <br />
            Our experienced team, recognized for its expertise in DevOps
            methodologies, is dedicated to optimizing your workflows, automating
            repetitive tasks, and ensuring seamless collaboration between
            development and operations teams.
            <br />
            At StarOps, we understand the evolving landscape of IT operations,
            and we go beyond traditional practices to deliver innovative
            solutions. Whether it's accelerating deployment processes, improving
            software delivery cycles, or enhancing the overall efficiency of
            your operations, we're committed to providing solutions that align
            with your business objectives.
            <br />
            Our commitment to excellence is reflected in our agile approach to
            DevOps, ensuring adaptability to the evolving needs of your
            projects. Security and confidentiality remain paramount,
            guaranteeing a trustworthy partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where our
            expertise in DevOps is harnessed to shape the future of seamless
            operations for your business. Join us as we optimize, innovate, and
            elevate your operational capabilities, one DevOps project at a time.
          </p>
          <div className="w-full md:w-1/3 flex items-start justify-center">
            <img
              className="w-full "
              src="https://cdn.staropstech.com/starops/assets/services/devops.webp"
              alt="devops services starops"
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Efficient DevOps Solutions"
          description="Empowering businesses with streamlined software development and IT operations using innovative DevOps practices."
        />
        <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.devops.ourServices.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 py-10 bg-gradient-to-r from-slate-200 to-slate-300">
        <div className=" p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="DevOps Case Studies"
            description="Uncover our DevOps success stories, featuring specialized solutions addressing a range of challenges and ensuring seamless development and operations collaboration."
          />

          <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.devops.caseStudies.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.devops.FAQ} />
      </div>
    </>
  );
};

export default SAAS;
