import React, { useState, useEffect } from "react";

import staropslogo from "../../Assets/logo.png";
import NavbarCard from "./NavbarCard";
import NavbarImageCard from "./NavbarImageCard";
import data from "../../data/navbar.json";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [isMenueVisible, setIsMenueVisible] = useState(true);
  const changeVisible = () => {
    setIsMenueVisible((prev) => !prev);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMenueVisible(window.innerWidth > 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navOpen]);
  return (
    <nav className="w-full fixed top-0 left-0 z-10  shadow-md bg-white">
      <div className="container  m-auto flex flex-col md:flex-row items-center justify-between ">
        <div className="flex flex-row w-full md:w-auto justify-between px-4 md:px-0">
          <Link to="/" className="text-lg font-bold">
            <img src={staropslogo} alt="starops logo" width="60" height="60" />
          </Link>
          <button className="md:hidden" onClick={changeVisible}>
            {!isMenueVisible ? (
              <img
                className="h-8 w-8"
                src="https://cdn-icons-png.flaticon.com/128/6015/6015685.png"
                alt="starops menue"
              />
            ) : (
              <img
                className="h-6 aspect-square"
                src="https://cdn-icons-png.flaticon.com/128/1828/1828778.png"
                alt="starops menue"
              />
            )}
          </button>
        </div>
        {isMenueVisible && (
          <div className="grid grid-cols-1 gap-1  w-auto  md:flex flex-row md:space-x-3 justify-end ">
            <div className="group text-center">
              <Link
                onClick={() => setNavOpen((prev) => !prev)}
                to="/"
                className="text-lg hover:text-logo"
              >
                Home
              </Link>
            </div>
            <div className="group text-center">
              <Link
                onClick={() => setNavOpen((prev) => !prev)}
                to="/about"
                className="text-lg hover:text-logo"
              >
                About
              </Link>
            </div>
            <div className="group text-center mx-auto ">
              <div className="text-lg cursor-pointer  hover:text-logo group-hover:text-logo flex flex-row items-center justify-center">
                Services
                <svg
                  className="w-2.5 h-2.5 ml-2.5 group-hover:rotate-180 transition-all duration-700 ease-in-out "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>
              <div className="hidden group-hover:block md:absolute inset-0 md:mt-10 md:h-10  bg-transparent w-full  ">
                <div className="md:mt-5 mx-auto max-h-[50vh]  overflow-scroll  w-[100vw]  grid grid-cols-2 md:grid-cols-4 gap-2 bg-white border md:py-4 md:shadow-md  md:px-56 ">
                  {data.services.map((value, key) => {
                    return (
                      <NavbarCard
                        data={value}
                        key={key}
                        setNavOpen={setNavOpen}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="group text-center  mx-auto ">
              <div className="text-lg cursor-pointer hover:text-logo group-hover:text-logo flex flex-row items-center justify-center">
                Expertise
                <svg
                  className="w-2.5 h-2.5 ml-2.5 group-hover:rotate-180 transition-all duration-700 ease-in-out"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>
              <div className="hidden group-hover:block md:absolute inset-0 md:mt-10 md:h-10  bg-transparent w-full  ">
                <div className="md:mt-5 mx-auto max-h-[50vh]  overflow-scroll  w-[100vw]  grid grid-cols-2 md:grid-cols-4 gap-2 bg-white border md:py-4 md:shadow-md  md:px-56 ">
                  {data.expertise.map((value, key) => {
                    return (
                      <NavbarCard
                        data={value}
                        key={key}
                        setNavOpen={setNavOpen}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="group text-center  mx-auto ">
              <div className="text-lg cursor-pointer hover:text-logo group-hover:text-logo flex flex-row items-center justify-center">
                Insights
                <svg
                  className="w-2.5 h-2.5 ml-2.5 group-hover:rotate-180 transition-all duration-700 ease-in-out"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>
              <div className="hidden group-hover:block md:absolute inset-0 md:mt-10 md:h-10  bg-transparent w-full  ">
                <div className="md:mt-5 mx-auto max-h-[50vh]  overflow-scroll  w-[100vw] px-4 grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 bg-white border md:py-4 md:shadow-md  md:px-56  ">
                  {data.insights.map((value, key) => {
                    return (
                      <NavbarImageCard
                        data={value}
                        key={key}
                        setNavOpen={setNavOpen}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="group text-center  mx-auto ">
              <Link
                onClick={() => setNavOpen((prev) => !prev)}
                to="/contact"
                className="text-lg hover:text-logo  flex flex-row items-center justify-center"
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
