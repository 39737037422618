import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useInView } from "react-intersection-observer";
import data from "../data/about.json";
import SectionHeading from "../Components/Common/SectionHeading";

const CoreValuesCard = ({ data }) => {
  return (
    <div className=" flex flex-col items-center justify-center text-center p-4">
      <img className="h-14" src={data.iconUrl} alt={data.heading} />
      <h1 className="text-lg">{data.title}</h1>
      <p className="font-light">{data.description}</p>
    </div>
  );
};

const EmergingTech = ({ data }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center gap-3 p-4">
      <div className="flex items-center justify-start gap-3 w-full">
        <img
          className="h-8 aspect-square"
          src={data.iconUrl}
          alt={data.title}
        />
        <h1 className="text-lg">{data.title}</h1>
      </div>
      <p className="font-light text-justify">{data.description}</p>
    </div>
  );
};

const AboutusPage = () => {
  const [isInView, setInView] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    setInView(inView);
  }, [inView]);

  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const animationDuration = 2000; // 3 seconds
  const targetValue1 = 8; // Set your desired target value for count1
  const targetValue2 = 97; // Set your desired target value for count2
  const targetValue3 = 100; // Set your desired target value for count3
  const targetValue4 = 10; // Set your desired target value for count4
  useEffect(() => {
    let startTime;
    let animationFrameId;
    const startAnimation = () => {
      const updateCount = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;

        if (elapsed < animationDuration) {
          const progress = elapsed / animationDuration;
          const newValue1 = Math.floor(targetValue1 * progress);
          const newValue2 = Math.floor(targetValue2 * progress);
          const newValue3 = Math.floor(targetValue3 * progress);
          const newValue4 = Math.floor(targetValue4 * progress);
          setCount1(newValue1);
          setCount2(newValue2);
          setCount3(newValue3);
          setCount4(newValue4);
          animationFrameId = requestAnimationFrame(updateCount);
        } else {
          setCount1(targetValue1);
          setCount2(targetValue2);
          setCount3(targetValue3);
          setCount4(targetValue4);
          cancelAnimationFrame(animationFrameId);
        }
      };
      startTime = Date.now();
      animationFrameId = requestAnimationFrame(updateCount);
    };
    startAnimation();
    return () => cancelAnimationFrame(animationFrameId);
  }, [
    targetValue1,
    targetValue2,
    targetValue3,
    targetValue4,
    animationDuration,
    isInView,
  ]);

  return (
    <>
      <Helmet>
        <title>
          About | StarOps Technologies | Innovators in IT, Web, App, & DevOps
        </title>
        <meta
          name="description"
          content="Learn about StarOps Technologies, a leader in IT, web, app development, and DevOps. Discover our mission, values, and how we transform ideas into innovative solutions that drive success."
        />
      </Helmet>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full  py-10 ">
        <div className="flex flex-col md:flex-row container mx-auto ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center  text-white w-11/12 md:w-1/2   ">
            <h1 className="text-2xl mb-5">About Us</h1>
            <h1 className="text-3xl mb-5   md:text-5xl   md:mb-10">
              How we became the number one trusted development company
            </h1>
            <p className="text-base md:text-lg">
              StarOps is an Indian based Information Technology Services, IT
              Consulting, Business Services, and Software Development Company
              headquartered In Greater Noida, India with offices in Lucknow, And
              Rajasthan.
            </p>
            <Link
              to={"/contact"}
              className="py-4 px-10  border border-white hover:bg-white  hover:text-[#427ee6]  rounded-2xl my-5"
            >
              Schedule a Call
            </Link>
          </div>
          <div className="flex  justify-end  items-center p-2 md:p-0 w-11/12 md:w-1/2 ">
            <img
              className="w-2/3"
              src="https://cdn.staropstech.com/starops/assets/pages_banner/about_page.webp"
              alt="starops Intraction"
            />
          </div>
        </div>
      </div>
      <div className="my-14 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Trusted By Startups"
          description="We have the best team that can deliver the best result because follows
      the best practises according to the Industry"
        />
        <div className="w-full  mt-8 gap-4 md:gap-2 grid grid-cols-1  place-items-center sm:grid-cols-4 md:grid-cols-7 ">
          <img
            className="h-8"
            src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
            alt="udharibazaar logo"
          />
          <img
            className="h-8  "
            src="https://cdn.staropstech.com/clients/masterrajpaldsa/assets/logo.png"
            alt="udharibazaar logo"
          />
          <img
            className="h-8"
            src="https://mytaxaa.com/assets/images/logo-d.png"
            alt="udharibazaar logo"
          />
          <img
            className="h-8"
            src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
            alt="udharibazaar logo"
          />
          <img
            className="h-8"
            src="https://cdn.staropstech.com/clients/masterrajpaldsa/assets/logo.png"
            alt="udharibazaar logo"
          />
          <img
            className="h-8"
            src="https://mytaxaa.com/assets/images/logo-d.png"
            alt="udharibazaar logo"
          />
          <img
            className="h-8"
            src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
            alt="udharibazaar logo"
          />
        </div>
      </div>
      <div className="mt-20  p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Know Us Better"
          description="Our outstanding team ensures top-notch results through the implementation of industry-leading practices."
        />
        <div className="mt-10 w-full flex flex-col  md:flex-row gap-2  md:gap-6">
          <div className="border w-full md:w-4/6  border-logo border-opacity-50 p-4 shadow-lg rounded-lg text-base text-justify font-light">
            StarOps is an Indian based Information Technology Services, IT
            Consulting, Business Services, And Software Development Company
            Headquartered in Greater Noida, India With Offices In Lucknow, And
            Rajasthan.
            <br />
            <br /> Welcome to the world of Starops - a cutting-edge IT service
            and enterprise software development company at the forefront of the
            latest technology and trends!. We are strategically positioned to
            deliver innovative solutions that help organizations address the
            ever-evolving business needs and challenges in today's highly
            competitive industry. As a leading software development company, we
            stay ahead of the curve by continuously upgrading our skills and
            expertise in emerging technologies such as AI, ML, IoT, and Cloud
            Computing.
            <br />
            At Starops, we specialize in digital transformations and tailor-made
            services that cater to your specific business requirements,
            unleashing powerful and adaptable digital solutions that not only
            satisfy the needs of today but also unlock the endless opportunities
            of tomorrow. From software development to IT consulting, Starops is
            your one-stop-shop for all your technology needs. At Starops, we
            pride ourselves on our commitment to quality, innovation, and
            customer satisfaction. Get ready to experience a whole new level of
            innovation and excellence with Starops!
          </div>
          <div className="w-full md:w-2/6 flex flex-col gap-2 md:gap-6">
            {/* <img
          className=" object-cover rounded-lg"
          src="https://cdn.staropstech.com/starops/assets/Images/know_us_1.jpg"
          alt=""
        /> */}
            <img
              className=" object-cover rounded-lg"
              src="https://cdn.staropstech.com/starops/assets/Images/know_us_2.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        ref={ref}
        className="mt-20  container mx-auto flex flex-col items-center justify-center"
      >
        <SectionHeading
          heading="StarOps In Numbers"
          description="We take pride in our exceptional team, dedicated to delivering unparalleled results through adherence to industry-leading practices."
        />
        <div className="mt-10 w-full flex flex-row flex-wrap items-center gap-2 justify-center md:justify-between">
          <div className=" border border-logo  rounded-lg py-2 px-6 flex items-center justify-center flex-col w-64">
            <h1 className="text-6xl font-bold text-logo ">{count1}+</h1>
            <p className="text-2xl font-light ">Team Experience</p>
          </div>
          <div className=" border border-logo  rounded-lg py-2 px-6 flex items-center justify-center flex-col w-64">
            <h1 className="text-6xl font-bold text-logo">{count2}%</h1>
            <p className="text-2xl font-light ">Client Retention</p>
          </div>
          <div className=" border border-logo  rounded-lg py-2 px-6 flex items-center justify-center flex-col w-64">
            <h1 className="text-6xl font-bold text-logo">{count3}%</h1>
            <p className="text-2xl font-light ">Success Stories</p>
          </div>
          <div className=" border border-logo  rounded-lg py-2 px-6 flex items-center justify-center flex-col w-64">
            <h1 className="text-6xl font-bold text-logo">{count4}+</h1>
            <p className="text-2xl font-light ">Industries Covered</p>
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Our Core Value"
          description="Our exceptional team consistently delivers outstanding results by adhering to industry-best practices, reflecting our commitment to excellence."
        />
        <div className="mt-10 grid gap-2  grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.corevalues.map((value, key) => {
            return <CoreValuesCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Using Emerging Technologies"
          description="Capitalizing on cutting-edge technologies, our exceptional team ensures superior results by following industry-best practices."
        />
        <div className="mt-10 grid gap-2  grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.emergingtech.map((value, key) => {
            return <EmergingTech data={value} key={key} />;
          })}
        </div>
      </div>
    </>
  );
};

export default AboutusPage;
