import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/services.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const Product = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              Product Services
            </h1>
            <p className="text-base md:text-lg font-light">
              Elevate your business with cutting-edge product services provided
              by StarOps, a leading technology partner based in India. We
              specialize in delivering innovative and scalable solutions
              tailored to meet the unique needs of your business. Our dedicated
              team of experts is committed to ensuring your organization
              harnesses the full potential of our product services for optimized
              efficiency and growth.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Versatile Product Solutions"
          description="Customized products for streamlined business operations and guaranteed success."
        />
        <div className="mt-10 flex  flex-col md:flex-row gap-4 items-center justify-center">
          <p className="w-full md:w-2/3 font-light leading-6 ">
            Welcome to StarOps, your go-to for transformative product services.
            As a dynamic and client-centric organization, we specialize in
            creating tailored solutions that redefine user experiences. Our team
            of experts passionately brings your ideas to life, ensuring each
            product is a unique reflection of your brand's identity.
            <br />
            At StarOps, we recognize that the market demands more than just
            functional products; it craves innovative solutions that captivate
            and resonate with users. Leveraging our comprehensive skill set, we
            embark on a collaborative journey with our clients, transforming
            concepts into intuitive, visually stunning, and feature-rich
            offerings.
            <br />
            Our commitment to excellence is reflected in our agile development
            methodology, ensuring that we adapt to the evolving needs of your
            project efficiently. Quality and confidentiality remain at the
            forefront of our priorities, assuring you of a trustworthy
            partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where every
            detail is meticulously crafted, and every product we deliver is an
            embodiment of your aspirations. Join us in shaping the future of
            user experiences, one innovative offering at a time.
          </p>

          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-10/12"
              src="https://cdn.staropstech.com/starops/assets/services/product.webp"
              alt="starops website"
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Innovative Product Solutions"
          description="Empower your business with customized product solutions, designed for optimal functionality and an exceptional user experience."
        />

        <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.product.ourServices.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 py-10 bg-gradient-to-r from-slate-200 to-slate-300">
        <div className=" p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Product Case Studies"
            description="Explore our product development success stories, showcasing tailored solutions for diverse challenges and delivering exceptional user experiences."
          />
          <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.product.caseStudies.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.product.FAQ} />
      </div>
    </>
  );
};

export default Product;
