import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/services.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const ITConsulting = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              IT Consulting Services
            </h1>
            <p className="text-base md:text-lg font-light">
              Enhance your business through advanced IT consulting services from
              StarOps, a prominent technology partner based in India. Our focus
              is on providing creative and scalable solutions, customized to
              address the specific requirements of your business. Our devoted
              team of professionals is dedicated to ensuring that your
              organization fully leverages the capabilities of our IT consulting
              services, promoting efficiency and fostering growth.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Expert IT Consulting Services"
          description="Tailored IT consulting solutions for optimized business operations and success."
        />

        <div className="mt-10 flex  flex-col md:flex-row gap-4 items-center justify-center">
          <p className="w-full md:w-2/3 font-light leading-6 ">
            Welcome to StarOps, your go-to for transformative IT consulting
            services. As a dynamic and client-centric organization, we
            specialize in creating tailored solutions that redefine user
            experiences. Our team of experts passionately brings your ideas to
            life, ensuring each service is a unique reflection of your brand's
            identity.
            <br />
            At StarOps, we recognize that the industry demands more than just
            functional solutions; it craves innovative strategies that captivate
            and resonate with users. Leveraging our comprehensive skill set, we
            embark on a collaborative journey with our clients, transforming
            concepts into intuitive, visually stunning, and feature-rich
            offerings.
            <br />
            Our commitment to excellence is reflected in our agile consulting
            methodology, ensuring that we adapt to the evolving needs of your
            project efficiently. Quality and confidentiality remain at the
            forefront of our priorities, assuring you of a trustworthy
            partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where every
            detail is meticulously crafted, and every IT consulting service we
            deliver is an embodiment of your aspirations. Join us in shaping the
            future of strategic solutions, one innovative offering at a time.
          </p>

          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-10/12"
              src="https://cdn.staropstech.com/starops/assets/services/consulting.webp"
              alt="starops website"
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Strategic IT Offerings"
          description="Empower your business with customized IT solutions, designed for optimal functionality and an exceptional user experience."
        />
        <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.itConsultancy.ourServices.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 py-10 bg-gradient-to-r from-slate-200 to-slate-300">
        <div className=" p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="IT Solutions Case Studies"
            description="Explore our IT solutions success stories, showcasing tailored offerings for diverse challenges and delivering exceptional user experiences."
          />
          <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.itConsultancy.caseStudies.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.itConsultancy.FAQ} />
      </div>
    </>
  );
};

export default ITConsulting;
