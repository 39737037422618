import React from "react";
import { Helmet } from "react-helmet-async";
import SectionHeading from "../Components/Common/SectionHeading";

const GalleryPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Gallery | StarOps Technologies | IT, Web, App, & DevOps Innovation
        </title>
        <meta
          name="description"
          content="Explore the heart of StarOps Technologies. Our gallery showcases team spirit, company events, and the innovation driving our IT, web, app, and DevOps services. Discover the people behind our success."
        />
      </Helmet>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              GalleryStarOps is happy to share their feeling with you
            </h1>
            <p className="text-base md:text-lg font-light">
              StarOps Is An Indian Information Technology Services, IT
              Consulting, Business Services, And Software Development Company
              Headquartered In Greater Noida India With Offices In Lucknow, And
              Rajasthan.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-10 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Explore Our Gallery: Capturing Moments in Excellence"
          description="Discover a visual journey filled with exceptional moments, creativity, and quality. Our gallery showcases the best of our work, capturing the essence of excellence in every image."
        />

        <div className="mt-10 grid gap-6 grid-cols-1">
          <div className="grid grid-cols-3 gap-3 place-content-center">
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/sckrhd"
              alt="staropstech"
            />
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/bkn2ce"
              alt="starops"
            />
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/xtlz47"
              alt="starops"
            />
          </div>
          <div className="grid grid-cols-2 gap-3 place-items-center">
            <img
              className="max-h-48 aspect-video justify-self-end rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/y5k701"
              alt="starops"
            />
            <img
              className="max-h-48 aspect-video justify-self-start rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/83keog"
              alt="starops"
            />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/3cejtr"
              alt="starops"
            />
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/odxfs7"
              alt="starops"
            />
            <img
              className="max-h-48 aspect-video rounded-lg hover:scale-105 duration-500 ease-in-out"
              src="https://rb.gy/hbmyc4"
              alt="starops"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
