import React from "react";
import ServiceForm from "../Components/Common/ServiceForm";
import SectionHeading from "../Components/Common/SectionHeading";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact | StarOps Technologies | IT, Web, App, & DevOps Solutions
        </title>
        <meta
          name="description"
          content="Get in touch with StarOps Technologies. We're here to help with your IT, web, app development, and DevOps needs. Contact us today to start your journey towards innovation and efficiency."
        />
      </Helmet>
      <ServiceForm />
      <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
        <SectionHeading
          heading="Feel free to query us"
          description="We have the best team that can  deliver the best result because Follows the best Practises according to the Industry"
        />
        <div className="mt-10 grid w-full grid-cols-1 md:grid-cols-3 gap-5 md:gap-10  text-white">
          <a
            href="tel:+917530882800"
            className="border bg-gradient-to-r from-blue-500 to-blue-400   shadow-sm rounded-lg flex min-h-40 max-h-44 flex-col items-center gap-2 justify-center"
          >
            <svg
              className="h-14"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5562 14.5477L15.1007 15.0272C15.1007 15.0272 14.0181 16.167 11.0631 13.0559C8.10812 9.94484 9.1907 8.80507 9.1907 8.80507L9.47752 8.50311C10.1841 7.75924 10.2507 6.56497 9.63424 5.6931L8.37326 3.90961C7.61028 2.8305 6.13596 2.68795 5.26145 3.60864L3.69185 5.26114C3.25823 5.71766 2.96765 6.30945 3.00289 6.96594C3.09304 8.64546 3.81071 12.259 7.81536 16.4752C12.0621 20.9462 16.0468 21.1239 17.6763 20.9631C18.1917 20.9122 18.6399 20.6343 19.0011 20.254L20.4217 18.7584C21.3806 17.7489 21.1102 16.0182 19.8833 15.312L17.9728 14.2123C17.1672 13.7486 16.1858 13.8848 15.5562 14.5477Z"
                fill="#ffffff"
              />
              <path
                opacity="0.8"
                d="M13.2595 1.87983C13.3257 1.47094 13.7122 1.19357 14.1211 1.25976C14.1464 1.26461 14.2279 1.27983 14.2705 1.28933C14.3559 1.30834 14.4749 1.33759 14.6233 1.38082C14.9201 1.46726 15.3347 1.60967 15.8323 1.8378C16.8286 2.29456 18.1544 3.09356 19.5302 4.46936C20.906 5.84516 21.705 7.17097 22.1617 8.16725C22.3899 8.66487 22.5323 9.07947 22.6187 9.37625C22.6619 9.52466 22.6912 9.64369 22.7102 9.72901C22.7197 9.77168 22.7267 9.80594 22.7315 9.83125L22.7373 9.86245C22.8034 10.2713 22.5286 10.6739 22.1197 10.7401C21.712 10.8061 21.3279 10.53 21.2601 10.1231C21.258 10.1121 21.2522 10.0828 21.2461 10.0551C21.2337 9.9997 21.2124 9.91188 21.1786 9.79572C21.1109 9.56339 20.9934 9.21806 20.7982 8.79238C20.4084 7.94207 19.7074 6.76789 18.4695 5.53002C17.2317 4.29216 16.0575 3.59117 15.2072 3.20134C14.7815 3.00618 14.4362 2.88865 14.2038 2.82097C14.0877 2.78714 13.9417 2.75363 13.8863 2.7413C13.4793 2.67347 13.1935 2.28755 13.2595 1.87983Z"
                fill="#ffffff"
              />
            </svg>
            <p className="text-base font-medium ">+91 7530882800 </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://maps.app.goo.gl/5nEBPjxYDufJrMwP8"
            className="border  bg-gradient-to-r from-blue-400 to-blue-400   shadow-sm rounded-lg flex min-h-40 max-h-44 flex-col items-center gap-2 justify-center"
          >
            <svg
              className="h-14"
              fill="#000000"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#ffffff"
                d="M16.114-0.011c-6.559 0-12.114 5.587-12.114 12.204 0 6.93 6.439 14.017 10.77 18.998 0.017 0.020 0.717 0.797 1.579 0.797h0.076c0.863 0 1.558-0.777 1.575-0.797 4.064-4.672 10-12.377 10-18.998 0-6.618-4.333-12.204-11.886-12.204zM16.515 29.849c-0.035 0.035-0.086 0.074-0.131 0.107-0.046-0.032-0.096-0.072-0.133-0.107l-0.523-0.602c-4.106-4.71-9.729-11.161-9.729-17.055 0-5.532 4.632-10.205 10.114-10.205 6.829 0 9.886 5.125 9.886 10.205 0 4.474-3.192 10.416-9.485 17.657zM16.035 6.044c-3.313 0-6 2.686-6 6s2.687 6 6 6 6-2.687 6-6-2.686-6-6-6zM16.035 16.044c-2.206 0-4.046-1.838-4.046-4.044s1.794-4 4-4c2.207 0 4 1.794 4 4 0.001 2.206-1.747 4.044-3.954 4.044z"
              ></path>
            </svg>
            <p className="text-base font-medium ">Noida , Uttar Pradesh </p>
          </a>
          <a
            href="mailto:contact@staropstech.com"
            className="border  bg-gradient-to-r from-blue-400 to-blue-500   shadow-sm rounded-lg flex min-h-40 max-h-44 flex-col items-center justify-center"
          >
            <svg
              className="h-14"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#ffff"
                d="M510.678 112.275a70.823 70.823 0 00-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557 0-37.395 7.996-50.21 20.814-1.715 1.715-3.301 3.43-4.823 5.345-7.203 8.788-12.358 19.428-14.602 31.054A68.69 68.69 0 000 126.087V385.92c0 9.968 2.114 19.55 5.884 28.203 3.497 8.26 8.653 15.734 14.926 22.001a77.4 77.4 0 004.892 4.494 70.957 70.957 0 0045.319 16.32h369.958c17.18 0 33.108-6.145 45.323-16.384 1.718-1.386 3.305-2.844 4.891-4.43 6.27-6.267 11.425-13.741 14.994-22.001v-.064c3.769-8.653 5.812-18.171 5.812-28.138V126.087a72.986 72.986 0 00-1.321-13.812zM46.509 101.571c6.345-6.338 14.866-10.175 24.512-10.175h369.958c9.646 0 18.242 3.837 24.512 10.175a34.402 34.402 0 013.112 3.637L274.696 274.203c-5.348 4.687-11.954 7.002-18.696 7.002-6.674 0-13.276-2.315-18.695-7.002L43.472 105.136c.858-1.25 1.915-2.436 3.037-3.565zM36.334 385.92V142.735L176.658 265.15 36.405 387.435c-.071-.464-.071-.986-.071-1.515zm404.645 34.677H71.021c-6.281 0-12.158-1.651-17.174-4.552l147.978-128.959 13.815 12.018c11.561 10.046 26.028 15.134 40.36 15.134a61.64 61.64 0 0040.432-15.134l13.808-12.018 147.92 128.959c-5.023 2.901-10.9 4.552-17.181 4.552zm34.687-34.677c0 .529 0 1.051-.068 1.515L335.346 265.221 475.666 142.8v243.12z"
              ></path>
            </svg>
            <p className="text-base font-medium ">contact@staropstech.com</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
