import React from "react";
import blogdata from "../../data/blog/blogs.json";
import SectionHeading from "./SectionHeading";

const BlogCard = ({ data }) => {
  return (
    <a
      href={`/blog/${data.title}`}
      className="w-full p-4 border rounded-lg flex flex-col justify-between gap-4"
    >
      <img
        className="rounded-lg object-fit h-44 sm:h-52 w-full"
        src={data.posterUrl}
        alt="starops article"
      />
      <p className="font-semibold">{data.heading}</p>
      <p className="text-justify font-light">{data.description}</p>
      <p className="bg-logo rounded-lg  mx-auto w-max shadow-blue-300 shadow-sm px-3 py-1 text-white">
        Read More
      </p>
    </a>
  );
};

const RecentsBlogs = ({ heading, recents }) => {
  return (
    <div className="mt-20 p-2 md:p-0 container mx-auto flex flex-col items-center justify-center">
      <SectionHeading
        heading={heading || "Latest Updates"}
        description="News, Insights, Tools, And Technologies From Software Development Company"
      />
      <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-4 ">
        {recents.map((value, key) => (
          <BlogCard
            data={blogdata.blogs.filter((item) => item.id === value)[0]}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};

export default RecentsBlogs;
