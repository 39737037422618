import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import RootLayout from "./Layouts/RootLayout";
import Homepage from "./Pages/Homepage";
import AboutusPage from "./Pages/AboutUsPage";
import CareerPage from "./Pages/CareerPage";
import AppDevlopment from "./Pages/Services/AppDevlopment";
import WebDevelopment from "./Pages/Services/WebDevelopment";
import SAAS from "./Pages/Services/SAAS";
import Devops from "./Pages/Services/Devops";
import Product from "./Pages/Services/Product";
import ITConsulting from "./Pages/Services/ITConsulting";
import ReactJs from "./Pages/Expertise/ReactJs";
import Python from "./Pages/Expertise/Python";
import NextJs from "./Pages/Expertise/NextJs";
import Django from "./Pages/Expertise/Django";
import DevopsExp from "./Pages/Expertise/DevopsExp";
import Flutter from "./Pages/Expertise/Flutter";
import GalleryPage from "./Pages/GalleryPage";
import ContactPage from "./Pages/ContactPage";
import ServicesLayout from "./Layouts/ServicesLayout";
import ExpertiseLayout from "./Layouts/ExpertiseLayout";
import BlogMainPage from "./Pages/Blogs/BlogMainPage";
import BlogLayout from "./Pages/Blogs/BlogLayout";

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route path="" element={<Homepage />} />
            <Route path="/about" element={<AboutusPage />} />
            <Route path="/careers" element={<CareerPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blog" element={<BlogMainPage />} />
            <Route path="/blog/:title" element={<BlogLayout />} />

            <Route path="/services" element={<ServicesLayout />}>
              <Route path="appdevelopment" element={<AppDevlopment />} />
              <Route path="webdevelopment" element={<WebDevelopment />} />
              <Route path="saas" element={<SAAS />} />
              <Route path="devops" element={<Devops />} />
              <Route path="product" element={<Product />} />
              <Route path="itconsulting" element={<ITConsulting />} />
            </Route>

            <Route path="/expertise" element={<ExpertiseLayout />}>
              <Route path="reactjs" element={<ReactJs />} />
              <Route path="python" element={<Python />} />
              <Route path="nextjs" element={<NextJs />} />
              <Route path="django" element={<Django />} />
              <Route path="devops" element={<DevopsExp />} />
              <Route path="flutter" element={<Flutter />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
