import React from "react";
import { Link } from "react-router-dom";

const NavbarCard = ({ data, setNavOpen }) => {
  return (
    <Link
      onClick={() => setNavOpen((prev) => !prev)}
      to={data?.url}
      className="w-full p-2 hover:shadow-md hover:shadow-blue-400 rounded-lg"
    >
      <div className="flex flex-row font-medium  items-center gap-2 hover:text-logo">
        <img
          className="h-5 w-5"
          src={data?.iconUrl}
          alt={`${data.title} starops`}
        />
        <h1>{data?.title}</h1>
      </div>
      <div className="text-left pt-2 leading-6 font-medium  text-gray-500">
        <p>{data?.description}</p>
      </div>
    </Link>
  );
};

export default NavbarCard;
