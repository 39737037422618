import React from "react";
import { Helmet } from "react-helmet-async";

import { Outlet } from "react-router-dom";

const ExpertiseLayout = () => {
  return (
    <>
      <Helmet>
        <title>
          Expertise | StarOps Technologies | IT, Web, App, & DevOps Mastery
        </title>
        <meta
          name="description"
          content="Explore the specialized expertise of StarOps Technologies in IT, web, app development, and DevOps. Our mastery in these areas drives innovation and delivers exceptional results for your business."
        />
      </Helmet>
      <Outlet />
    </>
  );
};

export default ExpertiseLayout;
