import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/services.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const WebDevelopment = () => {
  return (
    <div>
      <>
        <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
          <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
            <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
              <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
                Web Development Services
              </h1>
              <p className="text-base md:text-lg font-light">
                StarOps is a leading web development company based in India.
                With a strong reputation for delivering innovative and
                high-quality web solutions, StarOps is a trusted partner for
                businesses seeking to leverage the advantages of web
                development. Our team of skilled professionals is dedicated to
                crafting tailored web applications that align with your business
                goals, ensuring a seamless online presence.
              </p>
            </div>
            <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
          </div>
        </div>
        <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Innovative Web Development Solutions"
            description="Crafting customized web solutions with a client-centric approach, delivering exceptional user experiences."
          />
          <div className="mt-10 flex  flex-col md:flex-row gap-4 items-center justify-center">
            <p className="w-full md:w-2/3 font-light leading-6 ">
              Welcome to StarOps, your gateway to transformative web development
              services. As a dynamic and client-centric organization, we
              specialize in creating tailored web applications that redefine
              user experiences. Our team of seasoned experts, recognized for
              their award-winning prowess, passionately brings your ideas to
              life, ensuring each website is a unique reflection of your brand's
              identity.
              <br />
              At StarOps, we recognize that the digital landscape demands more
              than just functional websites; it craves innovative solutions that
              captivate and resonate with users. Leveraging our comprehensive
              skill set, we embark on a collaborative journey with our clients,
              delving deep into their visions to transform concepts into
              intuitive, visually stunning, and feature-rich web applications.
              <br />
              Our commitment to excellence is reflected in our agile development
              methodology, ensuring that we adapt to the evolving needs of your
              project efficiently. Security and confidentiality remain at the
              forefront of our priorities, assuring you of a trustworthy
              partnership.
              <br />
              Embark on a transformative collaboration with StarOps, where every
              line of code is meticulously crafted, and every website we deliver
              is an embodiment of your aspirations. Join us in shaping the
              future of web experiences, one innovative project at a time.
            </p>
            <div className="w-full md:w-1/3 flex items-center justify-center ">
              <img
                className="w-full"
                src="https://cdn.staropstech.com/starops/assets/services/web_dev.webp"
                alt="starops website"
              />
            </div>
          </div>
        </div>
        <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Our Web Development Solutions"
            description="Uncover the power of our web development services through impactful case studies. Experience customized solutions that address diverse challenges, showcasing our dedication to delivering excellence and exceptional user experiences."
          />

          <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {data.webdev.ourServices.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
        <div className="mt-20 py-10 bg-gradient-to-r from-slate-200 to-slate-300">
          <div className=" p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
            <SectionHeading
              heading="Web Development Case Studies"
              description="Discover our web development success stories, showcasing tailored solutions for diverse challenges and delivering outstanding user experiences."
            />
            <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
              {data.webdev.caseStudies.map((value, key) => {
                return <SimpleCard data={value} key={key} />;
              })}
            </div>
          </div>
        </div>
        <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
          <SectionHeading
            heading="Frequently Asked Question"
            description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
          />
          <FAQ data={data.webdev.FAQ} />
        </div>
      </>
    </div>
  );
};

export default WebDevelopment;
