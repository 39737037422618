import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

const ServicesLayout = () => {
  return (
    <>
      <Helmet>
        <title>
          Services | StarOps Technologies | IT, Web, App, & DevOps Solutions
        </title>
        <meta
          name="description"
          content="Explore the comprehensive IT, web, app development, and DevOps services offered by StarOps Technologies. We provide innovative solutions tailored to propel your business toward success and efficiency."
        />
      </Helmet>
      <Outlet />
    </>
  );
};

export default ServicesLayout;
