import React from "react";
import Navbar from "../Components/Home/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Home/Footer";

const RootLayout = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-[60px] mx-auto min-h-[80vh]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default RootLayout;
