import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/expertise.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const Django = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center text-white w-11/12 md:w-2/3">
            <h1 className="text-3xl mb-3 md:text-4xl md:mb-10">
              Expert Django Development
            </h1>
            <p className="text-base md:text-lg font-light">
              Supercharge your digital presence with StarOps - your reliable
              partner for expert Django development. Our skilled team crafts
              tailored solutions, ensuring innovation and seamless alignment
              with your business goals. Harness the power of Django for robust
              applications and elevate your online presence. Advance with
              StarOps.
            </p>
          </div>
          <div className="flex justify-end items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Pioneering Django Development"
          description="StarOps revolutionizes digital experiences with state-of-the-art Django solutions, providing excellence, innovation, and impact for your brand."
        />

        <div className="mt-10 flex  flex-col md:flex-row gap-4 items-center justify-center">
          <p className="w-full md:w-2/3 font-light leading-6 ">
            Welcome to StarOps, your gateway to cutting-edge Django development
            services. As a dynamic and client-centric organization, we
            specialize in crafting innovative applications that redefine user
            experiences through the power of Django.
            <br />
            Our seasoned experts, celebrated for their award-winning
            capabilities, passionately bring your ideas to life, ensuring each
            Django-powered solution is a unique representation of your brand.
            <br />
            At StarOps, we recognize the digital landscape's demand for more
            than just functional applications; it craves innovative solutions
            that captivate and resonate with users. Leveraging our expertise in
            Django, we embark on a collaborative journey with our clients,
            transforming concepts into intuitive, visually appealing, and
            feature-rich applications.
            <br />
            Our commitment to excellence is reflected in our agile Django
            development methodology, ensuring efficient adaptation to the
            evolving needs of your project. Security and confidentiality remain
            paramount in our priorities, providing you with a reliable
            partnership.
            <br />
            Embark on a transformative collaboration with StarOps, where every
            line of Django code is meticulously crafted, and every application
            we deliver is an embodiment of your aspirations. Join us in shaping
            the future of digital experiences, one innovative Django project at
            a time.
          </p>

          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-3/4"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/ban_django.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Explore StarOps Offerings"
          description="Discover our diverse solutions, where innovation meets expertise, ensuring tailored excellence for your unique business needs."
        />
        <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.django.whatweoffer.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
          <div className="row-start-1 row-end-4 items-center flex">
            <img
              className="h-auto"
              src="https://cdn.staropstech.com/starops/assets/expertise_details/django.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-20 py-4 bg-gradient-to-r from-slate-100 to-slate-100">
        <div className=" p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="Why Choose Us: Unlocking Exceptional Benefits"
            description="Experience unparalleled advantages as we bring expertise, innovation, and reliability to elevate your project to new heights."
          />

          <div className="mt-10 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.django.whychoose.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Experts in tailored mobile solutions with a client-centric approach, ensuring optimal user experience."
        />
        <FAQ data={data.django.FAQ} />
      </div>
    </>
  );
};

export default Django;
