import React from "react";
import SectionHeading from "../../Components/Common/SectionHeading";
import data from "../../data/pagesdata/services.json";
import SimpleCard from "../../Components/Common/SimpleCard";
import FAQ from "../../Components/Common/FAQ";

const AppDevlopment = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-500 to-green-500  w-full py-10">
        <div className="flex flex-col md:flex-row container mx-auto min-h-[40vh] ">
          <div className="p-2 md:p-0 flex flex-col items-start justify-center  text-white w-11/12 md:w-2/3   ">
            <h1 className="text-3xl mb-3   md:text-4xl  md:mb-10">
              App Development Services
            </h1>
            <p className="text-base md:text-lg font-light">
              StarOps excels in state-of-the-art app development solutions,
              revolutionizing the landscape of software creation and IT
              operations. Recognized for our innovative prowess, we refine
              development pipelines, elevate workflows, and provide top-tier
              services. Our adept professionals customize solutions to automate
              tasks, elevate deployment processes, and seamlessly integrate
              cutting-edge practices in app development, guaranteeing that your
              business attains unparalleled operational efficiency.
            </p>
          </div>
          <div className="flex  justify-end  items-center p-2 md:p-0 w-11/12 md:w-1/3 "></div>
        </div>
      </div>

      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Comprehensive Mobile App Development"
          description="Experts in tailored mobile solutions with a client-centric approach, ensuring optimal user experience."
        />
        <div className="mt-10 flex  flex-col md:flex-row gap-4">
          <p className="w-full md:w-2/3 font-light leading-6 items-center justify-center">
            Welcome to Starops, where innovation meets execution in the realm of
            cutting-edge app development services. As a dynamic and
            client-centric organization, we specialize in crafting bespoke
            mobile applications that redefine user experiences. Our team of
            seasoned experts, recognized for their award-winning prowess,
            passionately brings your ideas to life, ensuring each app is a
            unique testament to your brand's identity.
            <br />
            At Starops, we understand that the digital landscape demands more
            than just functional apps; it craves innovative solutions that
            captivate and resonate with users. Leveraging our comprehensive
            skill set, we embark on a journey with our clients, delving deep
            into their visions to transform concepts into intuitive, visually
            stunning, and feature-rich mobile applications.
            <br />
            Our commitment to excellence is reflected in our agile development
            methodology, ensuring that we adapt to the evolving needs of your
            project efficiently. Security and confidentiality are at the
            forefront of our priorities, assuring you of a trustworthy
            partnership.
            <br />
            Embark on a transformative collaboration with Starops, where every
            line of code is meticulously crafted, and every app we deliver is an
            embodiment of your aspirations. Join us in shaping the future of
            mobile experiences, one innovative app at a time.
          </p>
          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <img
              className="w-9/12"
              src="https://cdn.staropstech.com/starops/assets/services/mobile_dev.webp"
              alt="starops website"
            />
          </div>
        </div>
      </div>
      <div className="mt-20 p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
        <SectionHeading
          heading="Our App Development Solutions"
          description="Discover the excellence of our app development services through compelling case studies. Uncover tailored solutions that address diverse challenges, emphasizing our commitment to delivering superior user experiences."
        />
        <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.appdev.ourServices.map((value, key) => {
            return <SimpleCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="mt-20 py-10 bg-gradient-to-r from-slate-200 to-slate-300">
        <div className="p-4 md:p-0 container mx-auto  flex flex-col items-center justify-center group">
          <SectionHeading
            heading="App Development Case Studies"
            description="Explore our app development success stories, highlighting customized solutions for various challenges and delivering exceptional user experiences."
          />
          <div className="mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
            {data.appdev.caseStudies.map((value, key) => {
              return <SimpleCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <div className="mt-20 p-2 md:p-0 container mx-auto  flex flex-col items-center justify-center ">
        <SectionHeading
          heading="Frequently Asked Question"
          description="Unlock insights effortlessly with our FAQ section. Your questions, our expertise. Clear, concise answers tailored for you."
        />
        <FAQ data={data.appdev.FAQ} />
      </div>
    </>
  );
};

export default AppDevlopment;
